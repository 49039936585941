body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.active{
  color: #18d26e !important;
}

.nav-menu  ul  a {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 28px;
  text-decoration: none;
}



/* responsive 1234 */
*{
  margin:0;
  padding:0;
}

.mobile-menu {
  	display:block;
  	width:100%;
	
	line-height: 1.6em;
	font-weight: 400;
	width:100%;
    text-align:center;
	position: relative;
  	margin:0 auto;
}
/*Strip the ul of padding and list styling*/
.mobile-menu ul {
	list-style-type:none;
  margin: 0 auto;
  padding-left:0;
  text-align:center;
  width:100%;
  position: absolute;
  /* background: black; */
}

/*Create a horizontal list with spacing*/
.mobile-menu li {
	display:inline-block;
	/*float: left;
	margin-right: 1px;*/
}

/*Style for menu links*/
.mobile-menu li a {
	display:block;
	min-width:140px;
	text-align: center;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: #fff;
  text-transform:uppercase;
	/* background: black; */
	text-decoration: none;
  margin-left:-5px;
  padding: 10px 0;  
-webkit-transition: all 0.4s ease 0s;
-moz-transition: all 0.4s ease 0s;
-ms-transition: all 0.4s ease 0s;
-o-transition: all 0.4s ease 0s;
transition: all 0.4s ease 0s;
}

/*Hover state for top level links*/
.mobile-menu li:hover a {
	color: #18d26e;
  /* background-color:#0a0b0c; */
}

/*Style for dropdown links*/
.mobile-menu li:hover ul a {
	/* background: #000000; */
	color: #2f3036;
	height: 40px;
	line-height: 40px;
}

/*Hover state for dropdown links*/
.mobile-menu li:hover .mobile-menu ul a:hover {
	color: #4db3ff;
}

/*Hide dropdown links until they are needed*/
.mobile-menu li ul {
	display: none;
}

/*Make dropdown links vertical*/
.mobile-menu li ul li {
	display: block;
	float: none;
}

/*Prevent text wrapping*/
.mobile-menu li ul li a {
	width: auto;
	min-width: 100px;
	padding: 0 20px;
}


/*Style 'show menu' label button and hide it by default*/
.mobile-menu .show-menu {
	text-decoration: none;
	color: #fff;
	/* background: black; */
	text-align: center;
	padding: 10px 15px;
	display: none;
  cursor: pointer;
  text-transform: uppercase;
}

.mobile-menu .show-menu span{
   padding-left: 25px;
}

/*Hide checkbox*/
.mobile-menu input[type=checkbox]{
    display: none;
}

/*Show menu when invisible checkbox is checked*/
.mobile-menu input[type=checkbox]:checked ~ #menu{
    display: block;
}

/*Responsive Styles*/

@media screen and (max-width : 800px){
  .mobile-menu .lines {
    border-bottom: 15px double #f8f8f8; 
    border-top: 5px solid #f8f8f8; 
    content:"";
    height: 5px; 
    width:20px;
    padding-right:15px;
  float: right;
}
	/*Make dropdown links appear inline*/
	.mobile-menu ul {
		position: static;
		display: none;
	}
	/*Create vertical spacing*/
	.mobile-menu li {
		margin-bottom: 1px;
	}
	/*Make all menu links full width*/
	.mobile-menu ul li, .mobile-menu li a {
		width: 100%;
	}
	/*Display 'show menu' link*/
	.mobile-menu .show-menu {
		display:block;
	}
}